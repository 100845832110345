import React, { ChangeEvent } from "react";
import { Box } from "../../../components/elements/box/Box";
import { Section } from "../../../components/elements/section/Section";
import { UploadButton } from "../../../components/elements/button/upload/UploadButton";
import {
  useApiAsset,
  useApiDeleteMobileBundle,
  useApiMobileReport,
  useApiUploadMobileBundle,
} from "../../../hooks/queries/assetsContext";
import useToastContext from "../../../hooks/toastHook";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { Flex } from "../../../components/layouts/flex/Flex";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { triggerClickOnUrl } from "../../../shared/helper";
import { useSearchParams } from "react-router-dom";
import { Mixpanel } from "../../../shared/mixpanel";

const ANDROID_BUNDLE_TYPE = "application/vnd.android.package-archive"; // .apk files
const IOS_BUNDLE_TYPE = "application/zip"; // .ipa files are essentially zipped files
const WINDOWS_BUNDLE_TYPE = "application/vnd.ms-appx"; // .appx files

type Props = {
  assetId: number;
};
export const AssetMobileBox = (props: Props) => {
  const { assetId } = props;
  const [searchParams] = useSearchParams();

  // Get asset by id
  const { data: asset, refetch: refetchAsset } = useApiAsset(
    assetId,
    searchParams.has("admin-mode")
  );
  const addToast = useToastContext();
  const { mutate: uploadMobileBundle } = useApiUploadMobileBundle();
  const { mutate: deleteMobileBundle } = useApiDeleteMobileBundle();
  const { data: reportUrl } = useApiMobileReport(
    asset?.id,
    !!asset?.id && !!asset.mobile_app_report_s3_key
  );

  const getAppBundleName = (name: string) => {
    // Split the string by "-"
    const parts = name.split("-");

    // Exclude the prefix items at indexes 0, 1, and 2
    const filteredParts = parts.slice(4);

    // Return the union of the remaining items as a single string
    return filteredParts.join("-");
  };

  const handleOnClick = (event: ChangeEvent<HTMLInputElement>) => {
    // If bundle already uploaded, don't trigger upload
    if (asset?.mobile_app_bundle_s3_key) return;

    const bundle = event.target.files![0];

    if (asset)
      uploadMobileBundle({
        mobile_app_bundle: bundle,
        assetId: asset.id,
        onSuccessCallback(data) {
          addToast({
            type: "success",
            message: `Uploaded ${data.mobile_app_bundle_name}.`,
          });
          refetchAsset();
          Mixpanel.track("Upload mobile bundle");
        },
        onErrorCallback(error) {
          addToast({
            type: "error",
            message: `Failed to upload app bundle - Error: ${error.message}`,
          });
        },
      });
  };

  const handleOnDelete = () => {
    if (asset)
      deleteMobileBundle({
        assetId: asset.id,
        onSuccessCallback() {
          addToast({
            type: "success",
            message: `Delete Mobile App Bundle & Report.`,
          });
          refetchAsset();
          Mixpanel.track("Delete mobile bundle");
        },
        onErrorCallback(error) {
          addToast({
            type: "error",
            message: `Failed to delete app bundle - Error: ${error.message}`,
          });
        },
      });
  };

  return (
    <Box>
      <Flex justify="between" align="center" gap="16px">
        <Section title={"Mobile App Bundle"}>
          <UploadButton
            validFileTypes={[
              ANDROID_BUNDLE_TYPE,
              IOS_BUNDLE_TYPE,
              WINDOWS_BUNDLE_TYPE,
            ]}
            isExist={!!asset?.mobile_app_bundle_s3_key}
            fileName={getAppBundleName(asset?.mobile_app_bundle_s3_key || "")}
            label={"Upload Mobile App Bundle (APK/IPA/APPX)"}
            onClick={handleOnClick}
            onDelete={handleOnDelete}
            width={95}
          />
        </Section>
        <Section title={"Mobile Vulnerabilities Report"}>
          {!asset?.mobile_app_report_s3_key ? (
            <LabelRegular style={{ marginLeft: "8px" }}>
              Report currently unavailable.
            </LabelRegular>
          ) : (
            <LinkButton
              label="Download Report"
              iconName="download"
              onClick={() => {
                if (reportUrl?.report_download_url) {
                  triggerClickOnUrl(reportUrl?.report_download_url);
                }
              }}
            />
          )}
        </Section>
      </Flex>
    </Box>
  );
};
