import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  HeaderSecondary,
  LabelBold,
  LabelMedium,
} from "../../../components/elements/typography/Typography";
import { Asset, AssetEdit } from "../../../types/Asset";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { AssetDetailsBox } from "./AssetDetailsBox";
import { WebDetailsBox } from "./WebDetailsBox";
import {
  isTwoStringsArraysEqual,
  toBase64AssetsView,
} from "../../../shared/helper";
import {
  useApiAsset,
  useApiAssetsTags,
  useApiDeleteAsset,
  useApiUpdateAsset,
} from "../../../hooks/queries/assetsContext";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { convertAssetToEdit, margeEditAndAsset } from "../AssetUtils";
import { TagsLine } from "../../../components/composed/tagsLine/TagsLine";
import useToastContext from "../../../hooks/toastHook";
import RelatedVulnerabilitiesBox from "./RelatedVulnerabilitiesBox";
import { useMutationWithDelay } from "../../../hooks/utilsHooks";
import { Link, useSearchParams } from "react-router-dom";
import { Flex } from "../../../components/layouts/flex/Flex";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { AssetTech } from "./AssetTech";
import { Mixpanel } from "../../../shared/mixpanel";
import { Loading } from "../../../components/elements/loading/Loading";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import { AssetVerificationIndicator } from "../assetVerification/AssetVerificationIndicator";
import { NoDataAvailable } from "../../insights/NoDataAvailable";
import { emptyAssetsView } from "../filters/FiltersUtils";
import { TabButton } from "../../../components/elements/button/tab/TabButton";
import { AssetActivityLogs } from "../assetComments/AssetActivityLogs";
import { AssetWhois } from "./AssetWhois";
import { AssetCertificate } from "./AssetCertificate";
import { AssetConnections } from "./AssetConnections";
import AlertBanner from "../../../components/elements/toastTypes/AlertBanner";
import { useApiMe } from "../../../hooks/queries/meContext";
import { AssetSchema } from "./AssetSchema";
import { AssetMobileBox } from "./AssetMobileBox";

type Props = {
  onClose: () => void;
  assetId: number;
  setShowVerifyAssetModal?: Dispatch<SetStateAction<number>>;
};
type Tab = "main" | "activity-logs" | "web-details" | "connections";

export const AssetPane = (props: Props) => {
  const { onClose, assetId, setShowVerifyAssetModal } = props;
  useTrackPage("Assets - Panel", { asset_id: assetId });
  const addToast = useToastContext();
  const { data: me } = useApiMe();
  const theme = useContext(ThemeContext);
  const [searchParams] = useSearchParams();
  const { data: products } = useApiProducts();
  const { data: allTags } = useApiAssetsTags();

  // Get asset by id
  const { data: assetProp, isLoading: isLoadingAsset } = useApiAsset(
    assetId,
    searchParams.has("admin-mode")
  );
  const [asset, setAsset] = useState<Asset | undefined>(assetProp);
  const [editAsset, setEditAsset] = useState<AssetEdit | undefined>(
    convertAssetToEdit(assetProp)
  );

  // By default the pane is on view mode, edit is false
  const [isEditable, setIsEditable] = useState<boolean>(false);

  // By default the pane is on "main" tab
  // Show comment section tab (activity-logs) if redirected from notification
  const viewedCommentId = searchParams.get("commentId");
  const [selectedTab, setSelectedTab] = useState<Tab>(
    !!viewedCommentId ? "activity-logs" : "main"
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { mutate: updateAsset, isLoading } = useApiUpdateAsset();
  const {
    mutate: deleteAsset,
    isLoading: isDeleting,
    isSuccess,
  } = useApiDeleteAsset();
  const { runMutation, isRunning: isInDelay } = useMutationWithDelay(3000);

  useEffect(() => {
    if (!assetProp || !!asset) return;
    setAsset(assetProp);
    setEditAsset(convertAssetToEdit(assetProp));
  }, [assetProp, asset]);

  const hasChanges = (): boolean => {
    return (
      editAsset?.environment !== asset?.environment ||
      editAsset?.is_asm_enabled !== asset?.is_asm_enabled ||
      editAsset?.priority !== asset?.priority ||
      editAsset?.product !== asset?.product ||
      !isTwoStringsArraysEqual(editAsset?.tags, asset?.tags)
    );
  };

  const assetView = { ...emptyAssetsView };
  assetView.filters = [
    {
      column: "name",
      value: asset?.name || "",
      condition: "is",
      order: 0,
      next_condition: "and",
    },
  ];
  const encodedAssetView = toBase64AssetsView(assetView);

  const onUpdateAsset = () => {
    if (!!!editAsset) {
      addToast({ message: "No asset selected", type: "error" });
      return;
    }
    runMutation(
      () =>
        updateAsset({
          assetId: assetId,
          assetData: { ...editAsset },
          onSuccessCallback: (updatedAsset) => {
            !!asset &&
              setAsset(
                margeEditAndAsset(
                  { ...updatedAsset },
                  editAsset,
                  products || []
                )
              );
            setIsEditable(false);
            addToast({
              message: `Asset "${updatedAsset.name}" updated successfully `,
              type: "success",
            });
          },
          onErrorCallback: (err) => {
            addToast({
              message: `Failed to update asset "${asset?.name}" - ${err.message}`,
              type: "error",
            });
          },
        }),
      `Updating asset "${asset?.name}"`
    );
  };

  const handleDeleteAsset = () => {
    asset &&
      deleteAsset({
        assetId: assetId,
        assetData: asset,
        onSuccessCallback: () => {
          addToast({ type: "success", message: "Asset has been deleted" });
          setShowDeleteModal(false);
          onClose();
        },
        onErrorCallback: () =>
          addToast({ type: "error", message: "Failed to delete asset" }),
      });
  };
  return (
    <>
      <RightPane onClose={onClose} width="1008px">
        {isLoadingAsset ? (
          <Flex column h100 w100 justify="center" align="center">
            <Loading />
          </Flex>
        ) : !isLoadingAsset && !asset ? (
          <NoDataAvailable />
        ) : (
          <Flex
            column
            gap="24px"
            style={{
              padding: "32px",
              height: "100%",
              overflowY: "auto",
              marginRight: "10px",
            }}
          >
            {!isLoadingAsset && me?.customer.id !== asset?.customer && (
              <Flex w100 style={{ marginBottom: "16px" }}>
                <AlertBanner
                  message={
                    <>
                      <LabelBold>Attention!</LabelBold>
                      <LabelMedium>
                        You are viewing an asset of a different customer from
                        the one you are assigned to.
                      </LabelMedium>
                    </>
                  }
                  type="warning"
                />
              </Flex>
            )}
            <Flex column gap="16px">
              <Flex
                align="center"
                justify="between"
                gap="16px"
                className="w-100"
              >
                <Tooltip
                  content={asset?.name || ""}
                  placement="bottom"
                  isTextTruncate
                >
                  <HeaderSecondary
                    className="text-truncate"
                    style={{
                      maxWidth: "300px",
                      textTransform: "none",
                    }}
                  >
                    {asset?.name}
                  </HeaderSecondary>
                </Tooltip>
                <Flex align="center" gap="16px">
                  {isEditable ? (
                    <>
                      <Flex gap="1px" align="center">
                        <Icon
                          name="remove"
                          color={theme.redPrimary}
                          size={24}
                        />
                        <TextButton
                          label="Delete Asset"
                          onClick={() => setShowDeleteModal(true)}
                          color={theme.black900}
                        />
                      </Flex>
                      <SeparatorVertical height="24px" />
                      <TextButton
                        label="Cancel"
                        onClick={() => setIsEditable(false)}
                        dataTestId="cancel-asset-button"
                        color={theme.black900}
                      />
                      <SeparatorVertical height="24px" />
                    </>
                  ) : (
                    <SecondaryButton
                      size="small"
                      iconName="edit"
                      label="Edit"
                      onClick={() => {
                        setIsEditable(true);
                        setSelectedTab("main");
                        Mixpanel.track("Asset Pane - Click on 'Edit' button");
                      }}
                      dataTestId="edit-asset-button"
                    />
                  )}
                  {isEditable ? (
                    <MainButton
                      size="small"
                      iconName="save"
                      dataTestId="save-asset-button"
                      label="Save"
                      disabled={!hasChanges()}
                      inProgress={isLoading || isInDelay}
                      onClick={() => {
                        onUpdateAsset();
                        Mixpanel.track(
                          "Asset Pane - update asset",
                          editAsset || {}
                        );
                      }}
                    />
                  ) : (
                    <Link
                      to={`/assets/map?view=${encodedAssetView}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <MainButton
                        size="small"
                        iconName="assetsOutline"
                        label="View on Map"
                        onClick={() => {}}
                      />
                    </Link>
                  )}
                </Flex>
              </Flex>
              <Flex align="center" gap="16px">
                <AssetVerificationIndicator
                  asset={asset}
                  setShowVerifyAssetModal={setShowVerifyAssetModal}
                  onOpenModal={onClose}
                />
                <SeparatorVertical height="16px" />

                <TagsLine
                  isEditable={isEditable}
                  selectedTags={
                    isEditable ? editAsset?.tags || [] : asset?.tags || []
                  }
                  allTags={allTags || []}
                  onTagRemove={(tag: string) =>
                    setEditAsset(
                      editAsset
                        ? {
                            ...editAsset,
                            tags: (editAsset.tags || []).filter(
                              (t) => t !== tag
                            ),
                          }
                        : undefined
                    )
                  }
                  onTagAdd={(tag: string) => {
                    setEditAsset(
                      editAsset
                        ? {
                            ...editAsset,
                            tags: [...(editAsset.tags || []), tag],
                          }
                        : undefined
                    );
                  }}
                />
              </Flex>
            </Flex>

            <Flex column gap="12px">
              {/* TAB SELECTOR */}
              {!isEditable && (
                <Flex gap="32px">
                  <TabButton
                    label="Asset Info"
                    iconName="assetsOutline"
                    selected={selectedTab === "main"}
                    onClick={() => setSelectedTab("main")}
                  />
                  <TabButton
                    label="Activity Logs"
                    iconName="findings"
                    selected={selectedTab === "activity-logs"}
                    onClick={() => {
                      setSelectedTab("activity-logs");
                      Mixpanel.track(
                        "Asset Pane - Click on 'Activity Logs' tab"
                      );
                    }}
                  />
                  <TabButton
                    label="Web Details"
                    iconName="globe"
                    selected={selectedTab === "web-details"}
                    onClick={() => {
                      setSelectedTab("web-details");
                      Mixpanel.track("Asset Pane - Click on 'Web Details' tab");
                    }}
                  />
                  <TabButton
                    label="Connections"
                    iconName="map"
                    selected={selectedTab === "connections"}
                    onClick={() => {
                      setSelectedTab("connections");
                      Mixpanel.track("Asset Pane - Click on 'Connections' tab");
                    }}
                  />
                </Flex>
              )}

              {/* TAB CONTENT */}
              <Flex column gap="32px">
                {selectedTab === "main" && (
                  <>
                    <AssetDetailsBox
                      asset={asset}
                      isEditable={isEditable}
                      editAsset={editAsset}
                      setEditAsset={setEditAsset}
                    />
                    <AssetTech asset={asset} />
                    {asset?.type === "domain" && <AssetSchema asset={asset} />}
                    {asset?.type === "mobile" && (
                      <AssetMobileBox assetId={asset.id} />
                    )}
                    <RelatedVulnerabilitiesBox asset={asset} />
                  </>
                )}
                {selectedTab === "activity-logs" && (
                  <AssetActivityLogs asset={asset} />
                )}
                {selectedTab === "web-details" && (
                  <>
                    <WebDetailsBox asset={asset} />
                    <AssetCertificate asset={asset} />
                    <AssetWhois asset={asset} />
                  </>
                )}
                {selectedTab === "connections" && (
                  <AssetConnections
                    connections={asset?.third_party_connections}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
      </RightPane>
      {showDeleteModal && (
        <DeleteModal
          isSuccess={isSuccess}
          itemName={asset?.name || ""}
          onConfirm={handleDeleteAsset}
          onClose={() => setShowDeleteModal(false)}
          isLoading={isDeleting}
          itemType="Asset"
        />
      )}
    </>
  );
};
