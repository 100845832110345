import React, { useEffect, useState } from "react";
import { RichTextEditor } from "rich-text-editor";
import { Flex } from "../../layouts/flex/Flex";
import { MainButton } from "../button/main/MainButton";
import { useApiAccountsPaging } from "../../../hooks/queries/accountsContext";
import { ShortUser } from "../../../types/Me";
import styled from "styled-components";
import { BodyRegular } from "../typography/Typography";

const EditorContainer = styled.div`
  width: 100%;
  margin: 12px 0;

  cursor: text;

  border: 1px solid;
  border-radius: 0.6em;
  border-color: ${(props) => props.theme.separation};

  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;

  &:hover {
    border-color: ${(props) => props.theme.primary};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.primary};
  }
`;

type Props = {
  label?: string;
  required?: boolean;
  value?: string | null;
  initValue?: string | null;
  valueTrigger?: React.MutableRefObject<boolean>;
  clearTrigger?: React.MutableRefObject<boolean>;
  placeholderText?: string;
  onChange: (value: string, markdown: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
  theme?: any;
  allowMentions?: boolean;
  optionalMentionUsers?: ShortUser[];

  saveChangesMode?: boolean;
  isHidden?: boolean;
  isSaveDisabled?: boolean;
  queryStatus?: "error" | "idle" | "loading" | "success";
};
export const RichTextEditorWASP = (props: Props) => {
  const {
    saveChangesMode,
    label,
    required,
    onChange,
    onSave,
    onCancel,
    value,
    initValue,
    valueTrigger,
    clearTrigger,
    placeholderText,
    theme,
    allowMentions,
    optionalMentionUsers = [],
    isHidden = false,
    isSaveDisabled,
    queryStatus,
  } = props;

  // Get all customers accounts for mentioning users on comments
  const { data: accountsPages } = useApiAccountsPaging({}, !!allowMentions);

  const optionalMentionEmails =
    optionalMentionUsers.map((user) => user.email) || [];

  const getAccounts = (): ShortUser[] => {
    let accounts =
      accountsPages?.pages
        .map((page) => page.results || [])
        .flat()
        .map((a) => ({
          name: a.user.name,
          email: a.email,
          avatar_url: a.user.avatar_url || "",
        }))
        .filter((a) => !optionalMentionEmails.includes(a.email)) || [];

    return [...accounts, ...optionalMentionUsers];
  };

  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    // Hide editor after query success
    if (queryStatus === "success") setShowEditor(false);
  }, [queryStatus]);

  // console.log({ initValue, value });

  return isHidden && !showEditor ? (
    <EditorContainer
      style={{
        padding: "8px 16px",
        height: "240px",
        overflowY: "scroll",

        background:
          saveChangesMode && (!isSaveDisabled || initValue !== value)
            ? theme.blue50
            : "transparent",
      }}
      onClick={() => {
        setShowEditor(true);
        if (!!clearTrigger) clearTrigger.current = true;
        // Then we allow insertion of initial values
        if (!!valueTrigger) valueTrigger.current = true;
      }}
    >
      {value ? (
        <BodyRegular
          className="text-wrap w-100"
          style={{ overflowWrap: "anywhere" }}
          dangerouslySetInnerHTML={{ __html: value || "" }}
        />
      ) : (
        <BodyRegular color={theme.textSub}>{placeholderText}</BodyRegular>
      )}
    </EditorContainer>
  ) : (
    <>
      <EditorContainer
        style={{
          background:
            saveChangesMode && initValue !== value
              ? theme.blue50
              : "transparent",
        }}
      >
        <RichTextEditor
          label={label}
          required={required}
          value={value}
          valueTrigger={valueTrigger}
          clearTrigger={clearTrigger}
          placeholderText={placeholderText}
          onChange={onChange}
          theme={theme}
          users={getAccounts()}
        />
      </EditorContainer>
      {saveChangesMode && (
        <Flex gap="8px" justify="end">
          <MainButton
            variant="secondary"
            size="small"
            onClick={() => {
              if (onCancel) {
                // This is a workaround
                // We first clear the Rich Text Editor by triggering clear
                if (!!clearTrigger) clearTrigger.current = true;
                // Then we allow insertion of initial values
                if (!!valueTrigger) valueTrigger.current = true;
                // We change the value in the onCancel func
                onCancel();
              }
              setShowEditor(false);
            }}
            label={"Cancel"}
          />
          <MainButton
            iconName="send"
            size="small"
            onClick={() => onSave && onSave()}
            disabled={isSaveDisabled}
            label={"Save"}
            inProgress={queryStatus === "loading"}
          />
        </Flex>
      )}
    </>
  );
};
