import React, { useContext, useEffect, useState } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { LabelMedium } from "../../../../components/elements/typography/Typography";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { ThemeContext } from "styled-components";
import {
  Checkbox,
  CheckboxState,
} from "../../../../components/elements/checkbox/Checkbox";
import { useSearchParams } from "react-router-dom";
import {
  DEFAULT_COLOR_BY_RISK,
  DEFAULT_EXPAND_ALL,
  DEFAULT_SHOW_LABELS,
  DEFAULT_SHOW_NEW,
} from "./constants";

type MapLayersProps = {
  handleCollapseAllGroupsChange: (state: CheckboxState) => void;
};

export const MapLayers = ({
  handleCollapseAllGroupsChange,
}: MapLayersProps) => {
  const [isMapLayerCollapsed, setIsMapLayerCollapsed] = useState(true);
  const theme = useContext(ThemeContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [showLabels, setShowLabels] = useState(DEFAULT_SHOW_LABELS);
  const [colorByRisk, setColorByRisk] = useState(DEFAULT_COLOR_BY_RISK);
  const [expandAll, setExpandAll] = useState(DEFAULT_EXPAND_ALL);
  const [showNew, setShowNew] = useState(DEFAULT_SHOW_NEW);

  useEffect(() => {
    setShowLabels(
      searchParams.has("showLabels")
        ? searchParams.get("showLabels") === "true"
        : DEFAULT_SHOW_LABELS
    );
    setColorByRisk(
      searchParams.has("colorByRisk")
        ? searchParams.get("colorByRisk") === "true"
        : DEFAULT_COLOR_BY_RISK
    );
    setExpandAll(
      searchParams.has("expandAll")
        ? searchParams.get("expandAll") === "true"
        : DEFAULT_EXPAND_ALL
    );
    setShowNew(
      searchParams.has("showNew")
        ? searchParams.get("showNew") === "true"
        : DEFAULT_SHOW_NEW
    );
  }, [searchParams]);

  const updateSearchParams = (params: Record<string, string | boolean>) => {
    const newParams = new URLSearchParams(searchParams);
    Object.entries(params).forEach(([key, value]) => {
      newParams.set(key, String(value));
    });
    setSearchParams(newParams.toString());
  };

  return (
    <>
      <Flex align="center" gap="8px" justify="between" w100>
        <LabelMedium>Map Layers</LabelMedium>
        <IconButton
          iconName={isMapLayerCollapsed ? "chevronDown" : "chevronUp"}
          color={theme.black700}
          onClick={() => setIsMapLayerCollapsed(!isMapLayerCollapsed)}
          size="small"
        />
      </Flex>
      {!isMapLayerCollapsed && (
        <Flex column gap="16px">
          <Checkbox
            label="Expand / Collapse"
            labelColor={theme.textSecondary}
            hasMidCheck={false}
            size="small"
            state={expandAll ? "checked" : "unchecked"}
            onChange={(state) => {
              updateSearchParams({ expandAll: state === "checked" });
              handleCollapseAllGroupsChange(state);
            }}
          />
          <Checkbox
            label="Labels"
            size="small"
            labelColor={theme.textSecondary}
            hasMidCheck={false}
            state={showLabels ? "checked" : "unchecked"}
            onChange={(state) => {
              updateSearchParams({ showLabels: state === "checked" });
            }}
          />
          <Checkbox
            label="Findings"
            size="small"
            labelColor={theme.textSecondary}
            hasMidCheck={false}
            state={colorByRisk ? "checked" : "unchecked"}
            onChange={(state) => {
              updateSearchParams({ colorByRisk: state === "checked" });
            }}
          />
          <Checkbox
            label="New assets"
            size="small"
            labelColor={theme.textSecondary}
            hasMidCheck={false}
            state={showNew ? "checked" : "unchecked"}
            onChange={(state) => {
              updateSearchParams({ showNew: state === "checked" });
            }}
          />
        </Flex>
      )}
    </>
  );
};
