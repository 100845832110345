import { Dispatch, SetStateAction, useContext, useRef } from "react";
import { RichTextEditorWASP } from "../../../components/elements/richTextEditor/RichTextEditor";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { AdminFindingEdit, Finding } from "../../../types/Finding";
import { Box } from "../../../components/elements/box/Box";
import { onRichTextChangedHandler } from "../../../shared/formUtils";
import { uploadFindingTextAndImages } from "../../admin/research/ImageUtils";
import {
  useApiCreateFindingImages,
  useApiUpdateFinding,
} from "../../../hooks/queries/findingContext";
import useToastContext from "../../../hooks/toastHook";

type Props = {
  finding?: Finding | null;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  refetchFinding: () => void;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const BottomBoxEdit = (props: Props) => {
  const {
    finding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
    refetchFinding,
    setCachedFindingData,
  } = props;

  const theme = useContext(ThemeContext);
  const addToast = useToastContext();

  // RTE HANDLING
  const clearBasicInfoRTE = useRef(false);
  const initialValueTriggerBasicInfoRTE = useRef(true);
  const clearAttackDetailsRTE = useRef(false);
  const initialValueTriggerAttackDetailsRTE = useRef(true);
  const clearMitigationRTE = useRef(false);
  const initialValueTriggerMitigationRTE = useRef(true);

  const { mutate: createImage } = useApiCreateFindingImages();

  const {
    mutate: updateFinding,
    status: queryStatus,
    reset: resetQueryStatus,
  } = useApiUpdateFinding({
    "admin-mode": true,
  });

  const onSaveSuccessCallback = () => {
    refetchFinding();
    setTimeout(() => resetQueryStatus(), 2000);
  };

  const onSaveRTE = async () => {
    await uploadFindingTextAndImages(
      parseInt(`${finding?.id}`),
      editableUpdateFindingData?.description_wasp || "",
      editableUpdateFindingData?.attack_details_wasp || "",
      editableUpdateFindingData?.mitigation_wasp || "",
      editableUpdateFindingData?.description || "",
      editableUpdateFindingData?.attack_details || "",
      editableUpdateFindingData?.mitigation || "",
      editableUpdateFindingData?.description_html || "",
      editableUpdateFindingData?.attack_details_html || "",
      editableUpdateFindingData?.mitigation_html || "",
      createImage,
      updateFinding,
      undefined,
      addToast,
      onSaveSuccessCallback
    );
  };

  return (
    <Flex column>
      <Box>
        <Flex column>
          <LabelRegular>Description</LabelRegular>
          <RichTextEditorWASP
            isHidden
            saveChangesMode
            placeholderText="Provide a detailed description of the vulnerability discovered..."
            theme={theme}
            valueTrigger={initialValueTriggerBasicInfoRTE}
            clearTrigger={clearBasicInfoRTE}
            value={editableUpdateFindingData?.description_wasp}
            initValue={finding?.description_wasp}
            onChange={(html: string, markdown: string) => {
              onRichTextChangedHandler(
                html,
                "description_wasp",
                markdown,
                "description",
                setEditableUpdateFinding
              );
              setCachedFindingData &&
                setCachedFindingData({
                  description: markdown,
                  description_wasp: html,
                });
            }}
            isSaveDisabled={
              finding?.description_wasp ===
                editableUpdateFindingData?.description_wasp ||
              queryStatus !== "idle"
            }
            onSave={onSaveRTE}
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                description_wasp: finding?.description_wasp,
                description: finding?.description,
              }));
              setCachedFindingData &&
                setCachedFindingData({
                  description: finding?.description,
                  description_wasp: finding?.description_wasp,
                });
            }}
            queryStatus={queryStatus}
          />
        </Flex>
        <Flex column>
          <LabelRegular>Attack Details</LabelRegular>
          <RichTextEditorWASP
            isHidden
            saveChangesMode
            placeholderText="Outline the attack details..."
            isSaveDisabled={
              finding?.attack_details_wasp ===
                editableUpdateFindingData?.attack_details_wasp ||
              queryStatus !== "idle"
            }
            onSave={onSaveRTE}
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                attack_details: finding?.attack_details,
                attack_details_wasp: finding?.attack_details_wasp,
              }));
              setCachedFindingData &&
                setCachedFindingData({
                  attack_details: finding?.attack_details,
                  attack_details_wasp: finding?.attack_details_wasp,
                });
            }}
            value={editableUpdateFindingData?.attack_details_wasp}
            initValue={finding?.attack_details_wasp}
            valueTrigger={initialValueTriggerAttackDetailsRTE}
            clearTrigger={clearAttackDetailsRTE}
            onChange={(html: string, markdown: string) => {
              onRichTextChangedHandler(
                html,
                "attack_details_wasp",
                markdown,
                "attack_details",
                setEditableUpdateFinding
              );
              setCachedFindingData &&
                setCachedFindingData({
                  attack_details: markdown,
                  attack_details_wasp: html,
                });
            }}
            theme={theme}
            queryStatus={queryStatus}
          />
        </Flex>
        <Flex column>
          <LabelRegular>Mitigation</LabelRegular>
          <RichTextEditorWASP
            isHidden
            saveChangesMode
            placeholderText="Detail mitigation strategies and guidelines to prevent future exploits..."
            value={editableUpdateFindingData?.mitigation_wasp}
            theme={theme}
            initValue={finding?.mitigation_wasp}
            valueTrigger={initialValueTriggerMitigationRTE}
            clearTrigger={clearMitigationRTE}
            onChange={(html: string, markdown: string) => {
              onRichTextChangedHandler(
                html,
                "mitigation_wasp",
                markdown,
                "mitigation",
                setEditableUpdateFinding
              );
              setCachedFindingData &&
                setCachedFindingData({
                  mitigation: markdown,
                  mitigation_wasp: html,
                });
            }}
            isSaveDisabled={
              finding?.mitigation_wasp ===
                editableUpdateFindingData?.mitigation_wasp ||
              queryStatus !== "idle"
            }
            onSave={onSaveRTE}
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                mitigation_wasp: finding?.mitigation_wasp,
                mitigation: finding?.mitigation,
              }));
              setCachedFindingData &&
                setCachedFindingData({
                  mitigation: finding?.mitigation,
                  mitigation_wasp: finding?.mitigation_wasp,
                });
            }}
            queryStatus={queryStatus}
          />
        </Flex>
      </Box>
    </Flex>
  );
};
