import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import useToastContext from "./toastHook";

export function useOutsideTrigger(
  ref: React.RefObject<HTMLInputElement>,
  onClickOutside: () => void
) {
  useEffect(() => {
    /* Alert if clicked on outside of element */
    const handleClickOutside = (event: any) =>
      ref.current && !ref.current.contains(event.target) && onClickOutside();
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Unbind the event listener on clean up
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, onClickOutside]);
}

export function useScreenWidth(): number {
  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
  }, []);
  return width;
}

export function useScreenHeight(): number {
  const [height, setWindowHeight] = useState(0);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener("resize", () =>
      setWindowHeight(window.innerHeight)
    );
    return () =>
      window.removeEventListener("resize", () =>
        setWindowHeight(window.innerHeight)
      );
  }, []);
  return height;
}

export function useInfiniteScroll(
  observableElement: React.MutableRefObject<null>,
  hasNextPage: boolean,
  onNextPage: () => void
) {
  const handleObserver = useCallback(
    (entries: any) => {
      const [target] = entries;
      if (hasNextPage && target.isIntersecting) {
        onNextPage();
      }
    },
    [onNextPage, hasNextPage]
  );

  useEffect(() => {
    const element = observableElement.current;
    if (!element) return;
    const option = { threshold: 0 };

    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [onNextPage, hasNextPage, handleObserver, observableElement]);
}

export function useMutationWithDelay(duration?: number): {
  runMutation: (mutation: any, messageBeforeDelay: string) => void;
  isRunning: boolean;
} {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const addToast = useToastContext();
  const runMutation = (mutation: any, messageBeforeDelay: string) => {
    setIsUpdating(true);

    const timeout = setTimeout(() => {
      setIsUpdating(false);
      mutation();
    }, duration || 5000);
    addToast({
      message: messageBeforeDelay,
      type: "info",
      withUndo: true,
      durationMs: duration || 5000,
      onUndo: () => {
        setIsUpdating(false);
        clearTimeout(timeout);
      },
    });
  };

  return {
    runMutation,
    isRunning: isUpdating,
  };
}

// useDebounceCallback - Delay the callback function
// and cancel it if same function been called again
let timeout: NodeJS.Timeout;
export function useDebounceCallback(
  callBack: (...args: any) => any,
  delay: number = 1000
) {
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callBack(...args), delay);
  };
}

export function useToggle(
  defaultValue?: boolean
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
  const [value, setValue] = useState(!!defaultValue);
  const toggle = useCallback(() => setValue((x) => !x), []);
  return [value, toggle, setValue];
}

// Updates the height of a <textarea> when the value changes.
export const useAutoSizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);
};
