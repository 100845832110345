import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../../components/elements/box/Box";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
} from "../../../../components/elements/typography/Typography";
import {
  useApiAsset,
  useApiUpdateAsset,
} from "../../../../hooks/queries/assetsContext";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { AssetFindingsDistributions } from "./AssetFindingsDistributions";
import { getDomain } from "tldts";
import { priorityOptions } from "../../AssetUtils";
import { AssetGradeBadge } from "../../assetsTable/AssetGradeBadge";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { MainButton } from "../../../../components/elements/button/main/MainButton";
import { Link, useSearchParams } from "react-router-dom";
import { SecondaryButton } from "../../../../components/elements/button/secondary/SecondaryButton";
import useToastContext from "../../../../hooks/toastHook";
import { GraphAssetGlanceModalSkeleton } from "./GraphAssetGlanceModalSkeleton";
import { Icon } from "../../../../components/elements/icon/Icon";

type Props = {
  assetId: number;
  onClose: () => void;
};

export const GraphAssetGlanceModal = ({ assetId, onClose }: Props) => {
  const theme = useContext(ThemeContext);
  const { data: asset, isFetching: isFetchingAsset } = useApiAsset(assetId);
  const { data: products } = useApiProducts();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: updateAsset, isLoading } = useApiUpdateAsset();
  const addToast = useToastContext();

  const modalRef = useRef<HTMLDivElement>(null);
  const [modalPosition, setModalPosition] = useState({
    left: "40px",
    right: "auto",
  });

  useEffect(() => {
    const handlePosition = () => {
      if (modalRef.current) {
        const rect = modalRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        // Check if there's enough space on the right
        if (rect.right > viewportWidth) {
          setModalPosition({ right: "40px", left: "auto" }); // Move modal to the left
        } else {
          setModalPosition({ left: "40px", right: "auto" }); // Default position
        }
      }
    };

    handlePosition(); // Set position on mount
    window.addEventListener("resize", handlePosition); // Update position on resize

    return () => {
      window.removeEventListener("resize", handlePosition);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [onClose, searchParams, setSearchParams]);

  const handleAsmEnableChanged = () => {
    if (asset) {
      updateAsset({
        assetId: assetId,
        assetData: {
          ...asset,
          is_asm_enabled: !asset.is_asm_enabled,
        },
        onErrorCallback: (error) => {
          addToast({
            type: "error",
            message: "Failed to update asset",
          });
        },
        onSuccessCallback: () => {
          addToast({
            type: "success",
            message: "Asset updated successfully",
          });
        },
      });
    }
  };

  const getPriorityIconName = (priority: number) => {
    switch (priority) {
      case 1:
        return "priorityVeryLow";
      case 2:
        return "priorityLow";
      case 3:
        return "priorityMedium";
      case 4:
        return "priorityHigh";
      case 5:
        return "priorityVeryHigh";
      default:
        return "priorityVeryLow";
    }
  };

  return (
    <Box
      ref={modalRef}
      style={{
        position: "absolute",
        top: "-36px",
        ...modalPosition,
        width: "412px",
        backgroundColor: theme.bg3,
        boxShadow: "0px 0px 14px 0px #00000040",
        zIndex: 1000,
      }}
    >
      <Flex
        style={{
          backgroundColor: theme.bg3,
          borderStyle: "solid",
          borderColor: theme.black500,
          borderWidth:
            modalPosition.left !== "auto"
              ? "0px 0px 1px 1px"
              : "1px 1px 0px 0px",
          padding: "4px",
          height: "20px",
          width: "20px",
          position: "absolute",
          top: "40px",
          left: modalPosition.left !== "auto" ? "-10px" : "auto",
          right: modalPosition.right !== "auto" ? "-10px" : "auto",
          transform: "rotate(45deg)",
        }}
      ></Flex>
      {isFetchingAsset ? (
        <GraphAssetGlanceModalSkeleton />
      ) : (
        <Flex column gap="16px">
          <Flex column gap="8px">
            <Flex column w100>
              <HeaderSecondary
                title={asset?.name || ""}
                className="text-truncate"
              >
                {asset?.name}
              </HeaderSecondary>
            </Flex>
            <SeparatorHorizontal />
            {(asset?.related_findings?.length || 0) > 0 && (
              <BodyRegular color={theme.black650}>Findings</BodyRegular>
            )}
            {(asset?.related_findings?.length || 0) > 0 && (
              <AssetFindingsDistributions
                findingsIds={asset?.related_findings || []}
              />
            )}
            <Flex justify="between" align="center" gap="24px">
              <BodyRegular color={theme.black650}>Type</BodyRegular>
              <BodyBold>
                {getDomain(asset?.properties?.domain_name || "") ===
                asset?.properties?.domain_name
                  ? "Domain"
                  : "Subdomain"}
              </BodyBold>
            </Flex>
            <Flex justify="between" align="center" gap="24px">
              <BodyRegular color={theme.black650}>Priority</BodyRegular>
              <Flex align="center" gap="3px">
                <Icon name={getPriorityIconName(asset?.priority || 0)} />
                <BodyBold>
                  {
                    priorityOptions.find((p) => p.value === asset?.priority)
                      ?.label
                  }
                </BodyBold>
              </Flex>
            </Flex>
            <Flex justify="between" align="center" gap="24px">
              <BodyRegular color={theme.black650}>Security Grade</BodyRegular>
              <AssetGradeBadge riskScore={asset?.risk_score || 0} />
            </Flex>
            <Flex justify="between" align="center" gap="24px">
              <BodyRegular color={theme.black650}>Product</BodyRegular>
              <BodyBold>
                {products?.find((p) => p.id === asset?.product)?.name}
              </BodyBold>
            </Flex>
            <Flex justify="between" align="center" gap="24px">
              <BodyRegular color={theme.black650}>Environment</BodyRegular>
              <BodyBold>{asset?.environment}</BodyBold>
            </Flex>
            <Flex justify="between" align="center" gap="24px">
              <BodyRegular color={theme.black650}>IPs</BodyRegular>
              <BodyBold>{asset?.properties?.ip?.length}</BodyBold>
            </Flex>
          </Flex>
          <Flex justify="between" gap="8px" align="center" w100>
            <MainButton
              label="Asset Details"
              size="small"
              onClick={() => {
                const newParams = new URLSearchParams(searchParams);
                newParams.set("assetId", String(assetId));
                setSearchParams(newParams.toString());
              }}
            />
            <Link
              to={`/assets/map/${assetId}`}
              style={{ textDecoration: "none" }}
            >
              <SecondaryButton label={"Isolate Asset"} onClick={() => {}} />
            </Link>

            <SecondaryButton
              label={asset?.is_asm_enabled ? "Disable ASM" : "Enable ASM"}
              inProgress={isLoading}
              onClick={handleAsmEnableChanged}
              variant={asset?.is_asm_enabled ? "danger" : "primary"}
            />
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
