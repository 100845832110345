import { useContext, useState } from "react";
import Select, {
  GroupBase,
  MenuPlacement,
  SingleValue,
  components,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { ThemeContext } from "styled-components";
import { Icon } from "../icon/Icon";
import { dropdownStyles } from "./DropdownStyles";
import "./dropdown.css";
import { HeaderSubBold, LabelRegular } from "../typography/Typography";
import { Flex } from "../../layouts/flex/Flex";

export type Option = {
  value: string | number;
  label: string;
};

type Props = {
  value?: SingleValue<Option> | undefined;
  options?: Option[];
  onChange: (option: SingleValue<Option>) => void;
  onCreateOption?: (option: SingleValue<Option>) => void;
  width?: string;
  variant?: "border" | "outline";
  size?: "medium" | "large" | "small";
  placeholder?: string;
  valuePrefix?: string;
  disabled?: boolean;
  searchable?: boolean;
  creatable?: boolean;
  clearable?: boolean;
  isError?: boolean;
  isBold?: boolean;
  color?: string;
  styles?: { [key: string]: Function };
  iconName?: string;
  dataTestId?: string;
  customComponents?:
    | Partial<SelectComponents<Option, false, GroupBase<Option>>>
    | undefined;
  isMenuPositionFixed?: boolean;
  isCompact?: boolean;
  backgroundColor?: string;
  onMenuScrollToBottom?: (event: WheelEvent | TouchEvent) => void;
  onInputChange?: (newValue: string) => void;
  closeMenuOnSelect?: boolean;
  menuPlacement?: MenuPlacement;
  autoFocus?: boolean;
  queryStatus?: "error" | "idle" | "loading" | "success";
};

export function Dropdown(props: Props) {
  const {
    onChange,
    onCreateOption,
    value,
    styles,
    color,
    size,
    options = [],
    valuePrefix,
    placeholder = "Select an option",
    disabled = false,
    searchable = false,
    creatable = false,
    clearable = false,
    isError = false,
    isCompact = false,
    isBold = false,
    closeMenuOnSelect = true,
    iconName,
    variant = "outline",
    dataTestId,
    customComponents,
    width,
    isMenuPositionFixed,
    backgroundColor,
    onMenuScrollToBottom,
    onInputChange,
    menuPlacement,
    queryStatus,
    autoFocus,
  } = props;

  const theme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const placeholderOption: Option = { label: placeholder, value: placeholder };

  const handleOnChange = (opt: SingleValue<Option>) => {
    // make sure value is changed to prevent redundant queries
    if (opt?.value !== value?.value) onChange(opt);
  };

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <div
        data-testid={dataTestId}
        className="d-flex align-items-center dropdown-container"
      >
        {iconName && (
          <Icon
            name={iconName}
            size={size === "large" ? 32 : size === "medium" ? 24 : 16}
            color="inherit"
          />
        )}

        <Flex gap="8px" align="center">
          {valuePrefix && <LabelRegular>{valuePrefix}</LabelRegular>}
          {isBold ? (
            <HeaderSubBold>{props.data.label}</HeaderSubBold>
          ) : (
            props.data.label
          )}
        </Flex>
      </div>
    </components.SingleValue>
  );

  const DropdownIndicator = (props: any) => {
    const iconsMap: { [key: string]: { [key: string]: string } } = {
      idle: { name: "chevronDown", color: "inherit" },
      loading: { name: "spinner", color: "" },
      error: { name: "activeIssues", color: theme.redPrimary },
      success: { name: "check", color: theme.greenPrimary },
    };
    return (
      <components.DropdownIndicator {...props}>
        <Flex padding="0 8px 0 0">
          <Icon
            name={iconsMap[queryStatus || "idle"]?.name}
            color={iconsMap[queryStatus || "idle"]?.color}
            size={
              size === "large" ? "32px" : size === "medium" ? "24px" : "16px"
            }
          />
        </Flex>
      </components.DropdownIndicator>
    );
  };

  const dropDownStyles = dropdownStyles({
    backgroundColor,
    color,
    disabled,
    isError,
    isOpen,
    variant,
    size,
    theme,
  });

  return (
    <div style={{ width: width ? width : "inherit" }}>
      {creatable ? (
        <CreatableSelect
          options={options || []}
          value={value ? value : placeholderOption}
          placeholder={placeholder}
          isDisabled={disabled}
          isMulti={false}
          isClearable={clearable}
          openMenuOnFocus={true}
          onChange={handleOnChange}
          onMenuOpen={() =>
            (!queryStatus || queryStatus === "idle") && setIsOpen(true)
          }
          onMenuClose={() => setIsOpen(false)}
          onCreateOption={(e) =>
            onCreateOption
              ? onCreateOption({ value: e, label: e })
              : onChange({ value: e, label: e })
          }
          styles={{ ...dropDownStyles, ...styles }}
          components={
            !isCompact
              ? { SingleValue, DropdownIndicator, ...customComponents }
              : {}
          }
          inputId="select-role"
          menuPosition="fixed"
          menuPlacement={menuPlacement}
          onInputChange={onInputChange}
          onMenuScrollToBottom={onMenuScrollToBottom}
          menuIsOpen={isOpen}
          autoFocus={autoFocus}
        />
      ) : (
        <Select
          menuPlacement={menuPlacement}
          className={isOpen ? "wasp-dropdown" : ""}
          isDisabled={disabled || (!!queryStatus && queryStatus !== "idle")}
          isSearchable={searchable}
          onInputChange={onInputChange}
          isClearable={clearable}
          options={options || []}
          onChange={handleOnChange}
          value={value ? value : placeholderOption}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          styles={{ ...dropDownStyles, ...styles }}
          components={
            !isCompact
              ? {
                  SingleValue,
                  DropdownIndicator,
                  ...customComponents,
                }
              : {}
          }
          menuPosition={isMenuPositionFixed ? "fixed" : undefined}
          onMenuScrollToBottom={onMenuScrollToBottom}
          closeMenuOnSelect={closeMenuOnSelect}
          autoFocus={autoFocus}
        />
      )}
    </div>
  );
}
