import { Dispatch, SetStateAction, useState } from "react";
import { AdminFindingEdit, Finding } from "../../../types/Finding";
import { AssetsDropdown } from "../../projects/projectPane/AssetsDropdown";
import { useApiMe } from "../../../hooks/queries/meContext";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { SingleValue } from "react-select";
import {
  arraysToOptions,
  useUpdateFindingInPlace,
} from "../../../shared/formUtils";
import { TagBadge } from "../../../components/composed/tagsLine/TagBadge";
import { Flex } from "../../../components/layouts/flex/Flex";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useParams } from "react-router";

type Props = {
  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const EditAffectedMultiTenant = (props: Props) => {
  const {
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const { data: me } = useApiMe();
  const { data: products } = useApiProducts();
  const { id: updateFindingId } = useParams();

  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  const initProduct = products?.find((p) =>
    editableUpdateFindingData?.products?.includes(p.id)
  );

  const [selectedProduct, setSelectedProduct] = useState<SingleValue<Option>>(
    initProduct ? { label: initProduct.name, value: initProduct.id } : null
  );

  const onProductChangeHandler = (opt: SingleValue<Option>) => {
    setSelectedProduct(opt);
    setEditableUpdateFinding &&
      setEditableUpdateFinding((prev) => ({
        ...prev,
        products: [Number(opt?.value)],
        affected_assets: [],
        affected_assets_displayed: [],
      }));
    const data = {
      products: [Number(opt?.value)],
      affected_assets: [],
      affected_assets_displayed: [],
    };
    updateInPlace(data);
    setCachedFindingData && setCachedFindingData(data);
  };

  const onAssetsAddedHandler = (assetOption: SingleValue<Option>) => {
    if (!assetOption || !setEditableUpdateFinding) return;
    setEditableUpdateFinding((prev) => ({
      ...prev,
      affected_assets: prev?.affected_assets
        ? [...prev.affected_assets, Number(assetOption.value)]
        : [Number(assetOption.value)],
      affected_assets_displayed: prev?.affected_assets_displayed
        ? [...prev?.affected_assets_displayed, assetOption.label]
        : [assetOption.label],
    }));
    const data = {
      affected_assets: editableUpdateFindingData?.affected_assets
        ? [
            ...editableUpdateFindingData?.affected_assets,
            Number(assetOption.value),
          ]
        : [Number(assetOption.value)],
      affected_assets_displayed:
        editableUpdateFindingData?.affected_assets_displayed
          ? [
              ...editableUpdateFindingData?.affected_assets_displayed,
              assetOption.label,
            ]
          : [assetOption.label],
    };
    updateInPlace(data);
    setCachedFindingData && setCachedFindingData(data);
  };

  const onAssetsDeletedHandler = (assetOption: SingleValue<Option>) => {
    if (!assetOption || !setEditableUpdateFinding) return;
    setEditableUpdateFinding((prev) => ({
      ...prev,
      affected_assets: prev?.affected_assets
        ? prev.affected_assets.filter(
            (asset) => asset !== Number(assetOption.value)
          )
        : [],
      affected_assets_displayed: prev?.affected_assets_displayed
        ? prev.affected_assets_displayed.filter(
            (label) => label !== assetOption.label
          )
        : [],
    }));
    const data = {
      affected_assets: editableUpdateFindingData?.affected_assets
        ? editableUpdateFindingData.affected_assets.filter(
            (asset) => asset !== Number(assetOption.value)
          )
        : [],
      affected_assets_displayed:
        editableUpdateFindingData?.affected_assets_displayed
          ? editableUpdateFindingData.affected_assets_displayed.filter(
              (label) => label !== assetOption.label
            )
          : [],
    };
    updateInPlace(data);
    setCachedFindingData && setCachedFindingData(data);
  };

  const productsOptions = products?.map((p) => ({
    value: p.id,
    label: p.name,
  }));

  return (
    <Flex column gap="32px">
      <Flex column gap="8px">
        <LabelRegular>Affected Products</LabelRegular>
        <Dropdown
          width="100%"
          options={productsOptions}
          onChange={onProductChangeHandler}
          queryStatus={changedField === "products" ? queryStatus : undefined}
          value={selectedProduct}
          variant="border"
          size="medium"
          placeholder="Select Product"
        />
      </Flex>
      <Flex column gap="8px">
        <LabelRegular>Affected Assets</LabelRegular>
        <AssetsDropdown
          customer={me?.customer}
          disabled={
            !editableUpdateFindingData?.products?.length ||
            queryStatus !== "idle"
          }
          size="medium"
          selected={editableUpdateFindingData?.affected_assets || []}
          onChange={(opt) => {
            onAssetsAddedHandler(opt);
          }}
          productId={selectedProduct?.value as number}
        />
        <Flex flexWrap gap="8px">
          {editableUpdateFindingData?.affected_assets &&
            editableUpdateFindingData.affected_assets_displayed &&
            arraysToOptions(
              editableUpdateFindingData.affected_assets_displayed,
              editableUpdateFindingData.affected_assets
            )?.map((asset) => (
              <TagBadge
                key={asset.value}
                option={asset}
                onDeleteOption={onAssetsDeletedHandler}
                isEditable={queryStatus === "idle"}
              />
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
