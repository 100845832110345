import React, { CSSProperties, useState } from "react";
import { SingleValue } from "react-select";
import { IconButton } from "../../elements/button/icon/IconButton";
import { Option } from "../../elements/dropdowns/Dropdown";
import { TagDropdown } from "./TagDropdown";
import { TagBadge } from "./TagBadge";
import { Flex } from "../../layouts/flex/Flex";

type Props = {
  isEditable: boolean;
  selectedTags: string[];
  allTags?: string[];
  onTagAdd?: (tag: string) => void;
  onTagRemove?: (tag: string) => void;
  tagStyle?: CSSProperties;
  noWrap?: boolean;
  gap?: number;
  placeholder?: string;
};

export const TagsLine = (props: Props) => {
  const {
    isEditable,
    selectedTags,
    allTags,
    onTagAdd,
    onTagRemove,
    tagStyle,
    noWrap = false,
    gap = 8,
    placeholder = "Select a tag",
  } = props;
  const [isEditNewTag, setIsEditNewTag] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>("");
  const tagsOptions: Option[] =
    allTags
      ?.filter((tag) => !selectedTags.includes(tag))
      .map((tag: string) => ({
        label: tag,
        value: tag,
      })) || [];

  return (
    <Flex
      align="center"
      gap={`${gap || 8}px`}
      className={noWrap ? "" : "flex-wrap"}
      style={{ overflowX: "auto" }}
    >
      {selectedTags.map((tag: string, index: number) => (
        <TagBadge
          key={`tag-line-${tag}-${index}`}
          tag={tag}
          isEditable={isEditable}
          onDeleteTag={() => !!onTagRemove && onTagRemove(tag)}
          style={tagStyle}
        />
      ))}
      {isEditable && isEditNewTag && (
        <TagDropdown
          placeholder={placeholder}
          options={tagsOptions}
          value={newTag ? { label: newTag, value: newTag } : null}
          onChange={(option: SingleValue<Option>) => {
            setNewTag(option?.value.toString() || "");
            setIsEditNewTag(false);
            !!onTagAdd &&
              !!option?.value.toString() &&
              onTagAdd(option?.value.toString());
          }}
          onCancel={() => {
            setIsEditNewTag(false);
            !!onTagRemove && onTagRemove(newTag);
          }}
        />
      )}
      {isEditable && (
        <IconButton
          iconName="add"
          disabled={isEditNewTag}
          onClick={() => setIsEditNewTag(true)}
          size="small"
          dataTestId="add-tag-button"
        />
      )}
    </Flex>
  );
};
