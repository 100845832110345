import AlertBanner from "../../../components/elements/toastTypes/AlertBanner";
import {
  LabelBold,
  LabelMedium,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";

export const DifferentCustomerWarning = () => {
  return (
    <Flex w100 style={{ marginBottom: "16px" }}>
      <AlertBanner
        message={
          <>
            <LabelBold>Attention!</LabelBold>
            <LabelMedium>
              You are viewing a finding of a different customer from the one you
              are assigned to.
            </LabelMedium>
          </>
        }
        type="warning"
      />
    </Flex>
  );
};
