import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import {
  BodyRegular,
  HeaderSecondary,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { useApiAssetsGetById } from "../../../hooks/queries/assetsContext";
import { Finding } from "../../../types/Finding";
import { Flex } from "../../../components/layouts/flex/Flex";

export const AffectedAssetsPane = ({
  finding,
  onClose,
  onClickAsset,
}: {
  finding: Finding;
  onClose: () => void;
  onClickAsset?: (assetId: number) => void;
}) => {
  const theme = useContext(ThemeContext);
  const { data: assets } = useApiAssetsGetById(finding?.affected_assets || []);

  const [assetsSearchWord, setAssetsSearchWord] = useState("");
  const filteredAssets = assets?.filter(
    (a) => assetsSearchWord === "" || a.name.includes(assetsSearchWord)
  );

  return (
    <RightPane onClose={onClose} width="992px">
      <div
        className="d-flex flex-column gap-32"
        style={{
          padding: "32px",
          height: "100%",
          overflowY: "auto",
          marginRight: "10px",
        }}
      >
        <>
          <Flex align="center" gap="16px" className="w-100">
            <IconButton
              iconName="chevronLeft"
              color={theme.primary}
              size="medium"
              onClick={onClose}
            />
            <Flex column>
              <LabelRegular>{finding.title}</LabelRegular>
              <HeaderSecondary
                className="text-truncate"
                style={{ maxWidth: "300px", textTransform: "none" }}
              >
                Affected Assets
              </HeaderSecondary>
            </Flex>
            <Flex className="ms-auto">
              <InputText
                dataTestId="product-search-bar"
                onChange={(e) => setAssetsSearchWord(e.target.value)}
                placeholder="Search"
                iconName="search"
                width={"100%"}
              />
            </Flex>
          </Flex>
          <SeparatorHorizontal />

          {filteredAssets?.map((asset) => (
            <>
              <div className="d-flex align-items-center">
                <BodyRegular className="text-truncate">
                  {asset.name}
                </BodyRegular>
                <div className="ms-auto">
                  <IconButton
                    iconName="chevronRight"
                    size="small"
                    onClick={() => {
                      onClickAsset && onClickAsset(asset.id);
                      onClose();
                    }}
                    dataTestId={`open-asset-${asset.name}`}
                  />
                </div>
              </div>
              <SeparatorHorizontal />
            </>
          ))}
        </>
      </div>
    </RightPane>
  );
};
