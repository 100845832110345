import { createItem } from "../../../hooks/queries/sdk";

export type ImageHandlerReturn = {
  html: string;
  markdown: string;
  attachments: string[];
};

const uploadImage = async (findingId: number, base64: string, type: string) => {
  const data = await createItem(`findings/${findingId}/images`, {
    base64,
    type,
  });
  return data?.image_name;
};

export const handleFindingBase64Images = async (
  findingId: number,
  html: string,
  markdown: string,
  updateCountCallback: (count: number) => void
): Promise<ImageHandlerReturn> => {
  const regex = /<img[^>]*src="data:image\/([^;]+);base64,([^"]+)"[^>]*>/g;
  const matches = html.match(regex);
  var attachments: string[] = [];
  if (matches) {
    var count = matches.length;
    updateCountCallback(count);
    for (const match of matches) {
      const base64 = match.split(",")[1]?.split('"')[0];
      const type = match.split("/")[1].split(";")[0];
      const img = `data:image/${type};base64,${base64}`;
      const image_name = await uploadImage(findingId, base64, type);
      html = html.replace(
        img,
        `/api/v1/findings/${findingId}/images?image_name=${image_name}`
      );
      // replace the base64 image in markdown
      markdown = markdown.replace(img, image_name);
      attachments.push(image_name);
      count--;
      updateCountCallback(count);
    }
  }
  return { html, markdown, attachments };
};
