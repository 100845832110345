import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { PrimaryBadge } from "../../../components/elements/badge/PrimaryBadge";
import { Box } from "../../../components/elements/box/Box";
import { AffectedProductsList } from "../../findings/findingPane/items/AffectedProductsList";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { ImpactItem } from "../../findings/findingPane/items/ImpactItem";
import { CVSSItem } from "../../findings/findingPane/items/CVSSItem";
import { ExploitabilityItem } from "../../findings/findingPane/items/ExploitabilityItem";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { RiskItem } from "../../findings/findingPane/items/RiskItem";
import {
  JiraHtmlImageFixer,
  addClassToWaspHtmlImages,
} from "../../../shared/parser";
import { Finding } from "../../../types/Finding";
import { useApiMe } from "../../../hooks/queries/meContext";
import { getCustomerNameUrlFormat } from "../../../shared/helper";
import { AffectedAssetsDisplayedList } from "./AffectedAssetsDisplayedList";
import { light } from "../../../shared/theme";

type Props = {
  finding: Finding;
  index: number;
};

export const FindingPage = ({ finding, index }: Props) => {
  const { data: me } = useApiMe();
  const { data: products } = useApiProducts();
  if (!finding) return null;

  return (
    <Flex
      column
      gap="16px"
      className={`${index === 0 ? "mt-4" : "mt-5"}`}
      style={{ pageBreakAfter: "always" }}
      w100
      h100
    >
      <Flex column justify="center" gap="8px">
        <Flex align="baseline" gap="16px">
          <HeaderSubBold color={light.blue800}>{`Finding #${index + 1}: ${
            finding.title
          }`}</HeaderSubBold>
          <a
            href={`https://wasp.op-c.net/${getCustomerNameUrlFormat(
              me?.customer?.name || ""
            )}/finding-details/${finding.id}`}
            style={{
              fontSize: "12px",
            }}
          >
            Ticket #{finding?.id}
          </a>
        </Flex>
        <Flex align="center" gap="16px" flexWrap>
          {finding.cve_ids?.map((cve_id) => (
            <PrimaryBadge content={`CVE-${cve_id}`} key={`CVE-${cve_id}`} />
          ))}
          {!!finding.cwe_id && (
            <PrimaryBadge content={`CWE-${finding?.cwe_id}`} />
          )}
        </Flex>
      </Flex>
      <Flex
        column
        w100
        gap="16px"
        style={{
          display: "block",
        }}
      >
        <Box className="d-flex flex-column gap-24 w-100 box">
          <Flex>
            <RiskItem finding={finding} isReport />
            <ImpactItem finding={finding} isMitigationValues isReport />
            <ExploitabilityItem finding={finding} isReport />
            <CVSSItem
              finding={finding}
              noTooltip
              noSeparator
              withVector
              isReport
            />
          </Flex>

          <SeparatorHorizontal />
          {!!finding?.summary && (
            <Flex column gap="8px">
              <LabelRegular>Finding Summary</LabelRegular>
              <BodyRegular className="d-flex flex-wrap">
                {finding?.summary}
              </BodyRegular>
            </Flex>
          )}
          <AffectedProductsList
            finding={finding}
            products={products}
            isReport
          />
          <AffectedAssetsDisplayedList finding={finding} />
          <Flex column gap="8px">
            <LabelRegular style={{ color: light.black700 }}>
              Researcher Name
            </LabelRegular>
            <PrimaryBadge
              content={finding?.op_jira_assignee?.display_name || "N/A"}
            />
          </Flex>
        </Box>

        <Box className="d-flex flex-column gap-16 box border-bottom">
          {!!finding?.summary && (
            <>
              <HeaderSubBold style={{ color: light.black700 }}>
                Summary
              </HeaderSubBold>
              <BodyRegular style={{ color: light.black700 }}>
                {finding?.summary || ""}
              </BodyRegular>
            </>
          )}

          <HeaderSubBold style={{ color: light.black700 }}>
            Description
          </HeaderSubBold>
          <p
            className="text-wrap w-100 client-html"
            dangerouslySetInnerHTML={{
              __html: !!finding?.description_wasp
                ? addClassToWaspHtmlImages(finding?.description_wasp)
                : !!finding?.description_html
                  ? JiraHtmlImageFixer(finding?.description_html, finding?.id)
                  : finding?.description || "",
            }}
          />
        </Box>
        <Box className="d-flex flex-column gap-16 box border-bottom">
          <HeaderSubBold style={{ color: light.black700 }}>
            Attack Details
          </HeaderSubBold>
          <p
            className="text-wrap w-100 client-html"
            dangerouslySetInnerHTML={{
              __html: !!finding?.attack_details_wasp
                ? addClassToWaspHtmlImages(finding?.attack_details_wasp)
                : !!finding?.attack_details_html
                  ? JiraHtmlImageFixer(
                      finding?.attack_details_html,
                      finding?.id
                    )
                  : finding?.attack_details || "",
            }}
          />
        </Box>
        <Box
          className="d-flex flex-column gap-16 box border-bottom"
          style={{ pageBreakAfter: "always" }}
        >
          <HeaderSubBold style={{ color: light.black700 }}>
            Mitigation
          </HeaderSubBold>
          <p
            className="text-wrap w-100 client-html"
            dangerouslySetInnerHTML={{
              __html: !!finding?.mitigation_wasp
                ? addClassToWaspHtmlImages(finding?.mitigation_wasp)
                : !!finding?.mitigation_html
                  ? JiraHtmlImageFixer(finding?.mitigation_html, finding?.id)
                  : finding?.mitigation || "",
            }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
