import React, { useContext, useMemo, useState } from "react";
import { Asset } from "../../../../types/Asset";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyRegular,
  LabelMedium,
} from "../../../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { useApiFindings } from "../../../../hooks/queries/findingContext";
import { OPEN_STATUSES } from "../../../../types/Finding";
import { Badge } from "../../../../components/elements/badge/Badge";
import { Icon } from "../../../../components/elements/icon/Icon";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { getDomain } from "tldts";
import { getAssetLastPt, priorityOptions } from "../../AssetUtils";
import { AssetGradeBadge } from "../../assetsTable/AssetGradeBadge";
import { getDate } from "../../../../shared/helper";
import { useApiProjects } from "../../../../hooks/queries/projectsContext";
import { AssetIsolateDetailsColumnSkeleton } from "./AssetIsolateDetailsColumnSkeleton";
import { TextButton } from "../../../../components/elements/button/text/TextButton";
import { getGraphRiskBackgroundColor, getGraphRiskColor } from "./GraphHelper";

type Props = {
  asset: Asset;
};

export const AssetIsolateDetailsColumn = ({ asset }: Props) => {
  const [showMore, setShowMore] = useState(true);
  const theme = useContext(ThemeContext);
  const { data: openFindings, isFetching: isFetchingFinings } = useApiFindings({
    id: asset.related_findings?.join(","),
    status: OPEN_STATUSES.join(","),
  });

  const { data: products, isFetching: isFetchingProducts } = useApiProducts();
  const { data: projects, isFetching: isFetchingProjects } = useApiProjects();
  const lastPT = useMemo(
    () => (asset ? getAssetLastPt(asset, projects || []) : undefined),
    [asset, projects]
  );

  if (isFetchingFinings || isFetchingProducts || isFetchingProjects) {
    return <AssetIsolateDetailsColumnSkeleton />;
  }

  return (
    <Flex
      column
      gap="8px"
      style={{
        width: "240px",
      }}
    >
      {(openFindings || []).length > 0 && (
        <Flex column gap="6px">
          <BodyRegular
            color={theme.black650}
          >{`Findings (${openFindings?.length})`}</BodyRegular>
          {openFindings
            ?.sort((a, b) => b.overall_risk - a.overall_risk)
            ?.map((finding) => (
              <Badge
                color={theme[getGraphRiskColor(finding.overall_risk)]}
                backgroundColor={
                  theme[getGraphRiskBackgroundColor(finding.overall_risk)]
                }
                key={finding.id}
                style={{
                  height: "24px",
                  maxWidth: "240px",
                  borderRadius: "4px",
                }}
              >
                <Flex align="center" gap="4px">
                  <Icon size={20} name={"activeIssues"} />
                  <LabelMedium
                    className="text-truncate"
                    title={finding.title || ""}
                    color={theme[getGraphRiskColor(finding.overall_risk)]}
                  >
                    {finding.title}
                  </LabelMedium>
                </Flex>
              </Badge>
            ))}
        </Flex>
      )}
      {showMore && (
        <>
          <Flex column gap="2px">
            <BodyRegular color={theme.black650}>Product</BodyRegular>
            <BodyBold>
              {products?.find((p) => p.id === asset.product)?.name}
            </BodyBold>
          </Flex>
          <Flex column gap="2px">
            <BodyRegular color={theme.black650}>Environment</BodyRegular>
            <BodyBold className="text-capitalize">{asset.environment}</BodyBold>
          </Flex>
          <Flex column gap="2px">
            <BodyRegular color={theme.black650}>Type</BodyRegular>
            <BodyBold className="text-capitalize">
              {getDomain(asset?.properties?.domain_name || "") ===
              asset?.properties?.domain_name
                ? "Domain"
                : "Subdomain"}
            </BodyBold>
          </Flex>
          <Flex column gap="2px">
            <BodyRegular color={theme.black650}>Priority</BodyRegular>
            <BodyBold>
              {priorityOptions.find((p) => p.value === asset.priority)?.label}
            </BodyBold>
          </Flex>
          <Flex column gap="2px">
            <BodyRegular color={theme.black650}>Security Grade</BodyRegular>
            <AssetGradeBadge riskScore={asset?.risk_score || 0} />
          </Flex>
          <Flex column gap="2px">
            <BodyRegular color={theme.black650}>Discoverd on</BodyRegular>
            <BodyBold>{getDate(asset?.created_at || "")}</BodyBold>
          </Flex>
          <Flex column gap="2px">
            <BodyRegular color={theme.black650}>Last PT</BodyRegular>
            {asset && lastPT ? (
              <BodyBold>{getDate(lastPT.toISOString() || "")}</BodyBold>
            ) : (
              <BodyBold>None</BodyBold>
            )}
          </Flex>
          {(asset?.tags || []).length > 0 && (
            <Flex column gap="2px">
              <BodyRegular color={theme.black650}>Tags</BodyRegular>
              <Flex flexWrap align="center" gap="16px">
                {asset.tags?.map((tag) => (
                  <Badge
                    key={`tag-${tag}`}
                    color={theme.textBody}
                    backgroundColor={theme.black500}
                    style={{
                      borderRadius: "4px",
                    }}
                  >
                    {tag}
                  </Badge>
                ))}
              </Flex>
            </Flex>
          )}
        </>
      )}
      <TextButton
        label={`Show ${showMore ? "less" : "more"}`}
        onClick={() => setShowMore(!showMore)}
        style={{
          paddingLeft: "0",
        }}
      />
    </Flex>
  );
};
