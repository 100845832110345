import { SetStateAction, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../../../components/elements/badge/Badge";
import { PrimaryBadge } from "../../../../components/elements/badge/PrimaryBadge";
import { LabelRegular } from "../../../../components/elements/typography/Typography";
import { useApiAssetsGetById } from "../../../../hooks/queries/assetsContext";
import { Finding } from "../../../../types/Finding";

type Props = {
  finding: Finding | undefined | null;
  setShowAffectedAssetsPane?: React.Dispatch<SetStateAction<boolean>>;
  onClickAsset?: (assetId: number) => void;
};

export const AffectedAssetsList = (props: Props) => {
  const { finding, setShowAffectedAssetsPane, onClickAsset } = props;
  const theme = useContext(ThemeContext);

  const { data: assets } = useApiAssetsGetById(
    finding?.affected_assets || [],
    {}
  );

  return (
    <div className="d-flex flex-column gap-8" data-testid="affected-assets">
      <LabelRegular>Affected Assets</LabelRegular>
      <div className="d-flex flex-wrap gap-8">
        {assets
          ?.slice(0, 10)
          .map((affectedAsset, index) => (
            <PrimaryBadge
              content={affectedAsset.name}
              maxWidth="75vw"
              key={`affectedAsset-${index}`}
              onClick={() => onClickAsset && onClickAsset(affectedAsset.id)}
            />
          ))}
        {assets?.length && assets?.length > 10 ? (
          <Badge
            onClick={() =>
              setShowAffectedAssetsPane && setShowAffectedAssetsPane(true)
            }
            style={{
              cursor: "pointer",
              border: `1px solid ${theme.black500}`,
              color: theme.primary,
            }}
          >
            +{assets?.length - 10}
          </Badge>
        ) : null}
      </div>
    </div>
  );
};
