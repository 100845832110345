import { useNavigate } from "react-router";
import { PageNavButton } from "../../../components/elements/button/pageNav/PageNavButton";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Icon } from "../../../components/elements/icon/Icon";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { ThemeContext } from "styled-components";
import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Filters, findingsDefaultFilters } from "../Findings";
import { base64ToObject, deepEqual } from "../../../shared/helper";
import {
  useApiDeleteFinding,
  useApiFindingsIds,
} from "../../../hooks/queries/findingContext";
import { FindingsFilterLineTooltip } from "../filters/filterLine/FindingsFilterLineTooltip";
import {
  BodyRegular,
  LabelBold,
} from "../../../components/elements/typography/Typography";
import { Finding } from "../../../types/Finding";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import useToastContext from "../../../hooks/toastHook";
import { useApiMe } from "../../../hooks/queries/meContext";

type Props = {
  findingId?: string;
  isFetching?: boolean;
  finding?: Finding;
  currentFiltersBase64?: string;
  disabled?: boolean;
};

export const FindingsPageNavButton = (props: Props) => {
  const { isFetching, findingId, finding, currentFiltersBase64, disabled } =
    props;

  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isSuperuser = useIsSuperuser();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const addToast = useToastContext();

  const {
    mutate: deleteFinding,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    reset: resetDelete,
  } = useApiDeleteFinding();

  // get filters from url
  const filtersBase64 = searchParams.get("filters") || "";
  const urlFilters: Filters = filtersBase64 && base64ToObject(filtersBase64);

  const isFiltered: boolean =
    !!urlFilters && !deepEqual(urlFilters, findingsDefaultFilters);

  // Get list of filtered ids for paginating thru relevant findings
  const { data: filteredFindingsIds, isFetching: isFetchingIds } =
    useApiFindingsIds(urlFilters);

  const findingsIds = !!filteredFindingsIds?.length
    ? [0, ...filteredFindingsIds]
    : [];

  const currentFindingIdx = !!findingsIds?.length
    ? findingsIds?.indexOf(parseInt(`${findingId}`))
    : 1;
  const previousFindingIdx = currentFindingIdx && currentFindingIdx - 1;
  const nextFindingIdx = currentFindingIdx && currentFindingIdx + 1;

  const previousFindingId =
    findingsIds && previousFindingIdx ? findingsIds[previousFindingIdx] : 1;

  const nextFindingId =
    findingsIds && nextFindingIdx ? findingsIds[nextFindingIdx] : 1;

  const findingsCount = !!findingsIds?.length ? findingsIds?.length - 1 : 0;

  return (
    <Flex align="center" className="ms-auto" gap="12px">
      <Tooltip
        content={
          isFiltered ? <FindingsFilterLineTooltip filters={urlFilters} /> : ""
        }
      >
        <Flex
          align="center"
          gap="8px"
          style={{ opacity: isFiltered ? 1 : 0.3 }}
        >
          <Icon name="info" color={theme.primary} size={24} />
          <BodyRegular>Active Filters</BodyRegular>
        </Flex>
      </Tooltip>

      {(isSuperuser ||
        (me?.customer.is_multi_tenant &&
          finding?.source === "CUSTOMER_INSERTED")) && (
        <>
          <SecondaryButton
            label="Delete Finding"
            iconName="remove"
            variant="danger"
            size="small"
            onClick={() => setShowDeleteModal(true)}
          />

          {showDeleteModal && finding && (
            <DeleteModal
              itemType="Finding"
              itemName={finding?.title || "N/A"}
              onConfirm={() =>
                deleteFinding({
                  findingId: finding.id,
                  findingData: {},
                  onSuccessCallback: () => {
                    resetDelete();
                    setShowDeleteModal(false);
                    addToast({
                      message: `Finding "${finding.title}" deleted successfully`,
                      type: "success",
                    });
                    navigate(`/findings?filters=${currentFiltersBase64}`);
                  },
                  onErrorCallback: () => {
                    resetDelete();
                    addToast({
                      message: `Failed to delete finding "${finding.title}"`,
                      type: "error",
                    });
                  },
                })
              }
              onClose={() => {
                resetDelete();
                setShowDeleteModal(false);
              }}
              isLoading={isDeleting}
              isSuccess={isDeleted}
              body={
                <Flex column gap="8px" justify="center" align="center">
                  <BodyRegular>
                    Are you sure you want to delete this finding?
                  </BodyRegular>
                  <LabelBold>This action cannot be undone.</LabelBold>
                </Flex>
              }
            />
          )}
        </>
      )}

      <PageNavButton
        totalPages={findingsCount}
        currentPage={currentFindingIdx}
        onBackward={() => {
          if (previousFindingId)
            navigate(
              `/finding-details/${previousFindingId}?filters=${filtersBase64}`
            );
        }}
        onForward={() => {
          if (nextFindingId)
            navigate(
              `/finding-details/${nextFindingId}?filters=${filtersBase64}`
            );
        }}
        disabledLeft={
          disabled || isFetching || isFetchingIds || previousFindingId === 1
        }
        disabledRight={
          disabled ||
          isFetching ||
          isFetchingIds ||
          nextFindingIdx - 1 === findingsCount
        }
      />
    </Flex>
  );
};
