import { useEffect, useMemo, useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Switch } from "../../../components/elements/switch/Switch";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import {
  useApiAssetsPaging,
  useApiCreateAsset,
  useApiUpdateAsset,
  useApiUploadMobileBundle,
} from "../../../hooks/queries/assetsContext";
import { stringsListToSelectOptions } from "../../../shared/helper";
import useToastContext from "../../../hooks/toastHook";
import { Asset, AssetEdit, AssetType } from "../../../types/Asset";
import {
  OptionalField,
  RequiredField,
} from "../../../components/elements/requiredField/RequiredField";
import { FormError } from "../../../components/elements/FormsElements/FormError";
import { Customer } from "../../../types/Customer";
import { UploadButton } from "../../../components/elements/button/upload/UploadButton";

type Props = {
  newAssetName?: string;
  customer?: Customer;
  onApply?: (asset: Asset) => void;
  hideAsmButton?: boolean;
  hideParentAsset?: boolean;
  defaultProdEnvironment?: boolean;
  editedAsset?: Asset | null;
  isFormDisabled?: boolean;
};

export const AddAssetForm = (props: Props) => {
  const {
    newAssetName = "",
    customer,
    onApply,
    hideAsmButton,
    hideParentAsset,
    defaultProdEnvironment,
    editedAsset,
    isFormDisabled,
  } = props;

  const addToast = useToastContext();
  const isSuperuser = useIsSuperuser();

  const { data: products } = useApiProducts(
    customer ? { "admin-mode": true, customer: customer.id } : undefined
  );
  const {
    data: assets,
    hasNextPage,
    fetchNextPage,
  } = useApiAssetsPaging(
    customer ? { "admin-mode": true, customer: customer.id } : undefined
  );
  const { mutate: createAsset, isLoading: isCreating } = useApiCreateAsset(
    customer ? { "admin-mode": true, customer: customer.id } : undefined
  );
  const { mutate: updateAsset, isLoading: isUpdating } = useApiUpdateAsset();
  const { mutate: uploadMobileBundle } = useApiUploadMobileBundle();

  const getAssets = () => {
    return assets?.pages.map((page) => page.results || []).flat() || [];
  };

  const initAsset: AssetEdit = useMemo(
    () => ({
      name: newAssetName,
      parent_asset: null,
      product: products?.length === 1 ? products[0].id : null,
      environment: defaultProdEnvironment ? "Production" : "",
      is_asm_enabled: false,
      properties: {},
      source: "manual",
    }),
    [products, defaultProdEnvironment] // eslint-disable-line
  );

  const assetTypesOptions: Option[] = [
    { label: "Domain / IP", value: "domain" },
    { label: "Mobile App", value: "mobile" },
  ];
  const assetsOptions = getAssets().map((asset) => ({
    label: asset.name,
    value: asset.id,
  }));
  const productsOptions = products?.map((p) => ({
    value: p.id,
    label: p.name,
  }));
  const envOptions = stringsListToSelectOptions([
    "Testing",
    "Development",
    "Production",
    "Staging",
  ]);
  const [formValues, setFormValues] = useState<AssetEdit>(initAsset);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  useEffect(() => {
    setFormValues(
      editedAsset
        ? {
            name: editedAsset.name,
            parent_asset: editedAsset.parent_asset,
            product: editedAsset.product,
            environment: editedAsset.environment,
            is_asm_enabled: editedAsset.is_asm_enabled,
            properties: {
              domain_name: editedAsset.name,
            },
            type: "domain",
            source: "wasp",
          }
        : initAsset
    );
  }, [editedAsset, initAsset]);

  const validateDomain = (values: AssetEdit): string[] => {
    const errors: string[] = [];
    if (values?.name?.includes("www.") || values?.name?.includes("https"))
      errors?.push("Domain name should not contain 'www.' or https://");
    return errors;
  };

  const validateMobile = (values: AssetEdit): string[] => {
    const errors: string[] = [];
    if (!values.name || values.name === "")
      errors?.push("Mobile app name is empty");
    return errors;
  };

  const validate = (values: AssetEdit): string[] => {
    if (values.type === "domain") return validateDomain(values);
    else if (values.type === "mobile") return validateMobile(values);
    else return [];
  };

  const applyForm = () => {
    if (isFormDisabled) return;

    const errors = validate(formValues);
    if (!!errors.length) {
      setFormErrors(errors);
      return;
    }

    const mobileBundleUpload = (assetId: number) => {
      if (formValues.mobile_app_bundle)
        uploadMobileBundle({
          mobile_app_bundle: formValues.mobile_app_bundle,
          assetId: assetId,
          onSuccessCallback(data) {
            addToast({
              type: "success",
              message: `Uploaded ${data.mobile_app_bundle_name}.`,
            });
            console.log();
          },
          onErrorCallback(error) {
            addToast({
              type: "error",
              message: `Failed to upload app bundle - Error: ${error.message}`,
            });
          },
        });
    };

    const callbacks = {
      onSuccessCallback: (asset: Asset) => {
        if (asset.type === "mobile" && !editedAsset) {
          mobileBundleUpload(asset.id);
        }
        addToast({
          message: `Asset ${asset.name} ${
            editedAsset ? "updated" : "created"
          } successfully`,
          type: "success",
        });
        onApply && onApply(asset);
      },
      onErrorCallback(error: Error) {
        addToast({
          message: `Failed to ${
            editedAsset ? "update" : "create"
          } asset - ${error}`,
          type: "error",
        });
      },
    };

    if (editedAsset)
      updateAsset({
        assetId: editedAsset.id,
        assetData: {
          ...formValues,
          properties: { domain_name: formValues?.name },
        },
        ...callbacks,
      });
    else
      createAsset(
        customer
          ? {
              customer_id: customer.id,
              ...formValues,
              properties: { domain_name: formValues?.name },
              ...callbacks,
            }
          : {
              ...formValues,
              properties: { domain_name: formValues?.name },
              ...callbacks,
            }
      );
  };

  return (
    <Flex w100 column gap="24px">
      <Flex w100 column gap="8px">
        {customer && (
          <>
            <Flex gap="8px" align="center">
              <LabelRegular>Customer</LabelRegular>
              <RequiredField />
            </Flex>
            <InputText
              width="100%"
              placeholder={customer?.name}
              disabled={true}
            />
          </>
        )}
        <Flex w100 column gap="8px">
          <Flex gap="8px" align="center">
            <LabelRegular>Asset Type</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            dataTestId="asset-type"
            placeholder="Select asset type"
            options={assetTypesOptions}
            onChange={(opt) => {
              if (opt)
                setFormValues((prev) => ({
                  ...prev,
                  type: opt.value as AssetType,
                }));
            }}
            value={assetTypesOptions?.find(
              (opt) => formValues.type && opt.value === formValues.type
            )}
            variant="border"
            disabled={isFormDisabled}
          />
        </Flex>
        {formValues.type === "domain" && (
          <>
            <Flex gap="8px" align="center">
              <LabelRegular>Domain Name / IP Address</LabelRegular>
              <RequiredField />
            </Flex>
            <InputText
              width="100%"
              value={formValues.name ? formValues.name : newAssetName}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, name: e.target.value }))
              }
              isError={!!formErrors.length}
              dataTestId="asset-name"
              placeholder="your-domain-name.com"
              disabled={isFormDisabled}
            />
            {formErrors.forEach((e) => (
              <FormError errorMessage={e} />
            ))}

            {!hideParentAsset && (
              <Flex w100 column gap="8px">
                <Flex justify="between">
                  <LabelRegular>Parent Asset</LabelRegular>
                  <OptionalField />
                </Flex>
                <Dropdown
                  options={assetsOptions}
                  onChange={(opt) =>
                    setFormValues((prev) => ({
                      ...prev,
                      parent_asset: parseInt(`${opt?.value}`),
                    }))
                  }
                  value={assetsOptions?.find(
                    (opt) => opt.value === formValues.parent_asset
                  )}
                  variant="border"
                  onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
                  dataTestId="asset-parent"
                  searchable
                  disabled={isFormDisabled}
                />
              </Flex>
            )}
          </>
        )}
        {formValues.type === "mobile" && (
          <Flex column gap="8px">
            <Flex gap="8px" align="center">
              <LabelRegular>Mobile App Name</LabelRegular>
              <RequiredField />
            </Flex>
            <InputText
              width="100%"
              value={formValues.name ? formValues.name : newAssetName}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, name: e.target.value }))
              }
              dataTestId="asset-name"
              isError={!!formErrors.length}
              placeholder="your-secure-mobile-app"
              disabled={isFormDisabled}
            />
            {formErrors.map((e) => (
              <FormError errorMessage={e} />
            ))}
            <UploadButton
              label={"Upload Mobile App Bundle (APK/IPA)"}
              onClick={(event) => {
                setFormValues((prev) => ({
                  ...prev,
                  mobile_app_bundle: event.target.files![0],
                }));
              }}
              onDelete={() => {
                setFormValues((prev) => ({
                  ...prev,
                  mobile_app_bundle: undefined,
                }));
              }}
            />
          </Flex>
        )}
      </Flex>

      <Flex justify="between" gap="24px">
        <Flex w100 column gap="8px">
          <Flex gap="8px" align="center">
            <LabelRegular>Assign to Product</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            options={productsOptions}
            onChange={(opt) =>
              setFormValues((prev) => ({
                ...prev,
                product: parseInt(`${opt?.value}`),
              }))
            }
            value={productsOptions?.find(
              (opt) => opt.value === formValues.product
            )}
            variant="border"
            dataTestId="asset-product"
            disabled={isFormDisabled}
          />
        </Flex>
        <Flex w100 column gap="8px">
          <Flex gap="8px" align="center">
            <LabelRegular>Environment</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            options={envOptions}
            onChange={(opt) =>
              setFormValues((prev) => ({
                ...prev,
                environment: `${opt?.value}`,
              }))
            }
            value={envOptions?.find(
              (opt) => opt.value === formValues.environment
            )}
            variant="border"
            dataTestId="asset-environment"
            disabled={isFormDisabled}
          />
        </Flex>
      </Flex>

      {isSuperuser && !hideAsmButton && !customer && (
        <Flex justify="between" align="center">
          <Flex gap="8px" align="center">
            <BodyRegular>Enable ASM for this asset</BodyRegular>
          </Flex>
          <Switch
            checked={!!formValues.is_asm_enabled}
            onChange={(checked) =>
              setFormValues((prev) => ({ ...prev, is_asm_enabled: checked }))
            }
            dataTestId="asset-asm"
            disabled={isFormDisabled}
          />
        </Flex>
      )}
      <Flex justify="end">
        <MainButton
          label={editedAsset ? "update" : "Add Asset"}
          onClick={applyForm}
          inProgress={isCreating || isUpdating}
          disabled={
            isCreating ||
            (!formValues.name && !newAssetName) ||
            !formValues.product ||
            !formValues.environment ||
            isFormDisabled
          }
          dataTestId="add-asset-btn"
          size="medium"
        />
      </Flex>
    </Flex>
  );
};
